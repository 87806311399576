* {
  box-sizing: border-box;
}
html {
  font-size: 24px;
}

@media (max-width: 1280px) {
  html {
    font-size: 20px;
  }
}
@media (max-width: 1024px) {
  html {
    font-size: 18px;
  }
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
}
